<template>
    <div class="grid grid-cols-12 gap-4 mt-30">
        <!-- Title -->
        <div class="col-span-4 flex items-center ml-30 mb-22">
            <h1 class="font-4xl font-titillium-bold text-gray-800 opacity-85">{{ $t('formsPageTitle') }}</h1>
        </div>
        <!-- Display modal for init form -->
        <div class="col-span-8 flex justify-start md:justify-end items-center ml-30 md:ml-0 mb-22 mr-30 relative">
            <Button
                class="button w-257 bg-purple-500 text-white hover:bg-purple-600"
                role="button"
                :text="$t('formsAddFormBtnText')"
                @btnClick="showAddModal = true;"
            />
            <!-- Message box -->
            <div class="fixed top-20 right-30 z-10000" ref="successBoxContainer">
            </div>
        </div>
        <!-- Froms table -->
        <div
            class="col-span-12 mx-30 bg-white rounded-lg shadow"
            :class="[allForms.length ? 'py-30' : 'py-0']"
        >
            <v-table
                v-if="allForms.length !== 0"
                class="table"
                :data="allForms"
                :currentPage.sync="currentPage"
                :pageSize="pageSize"
                @totalPagesChanged="totalPages = $event"
                :hideSortIcons="true"
            >
                <!-- Forms table columns -->
                <thead
                    class=" bg-gray-100"
                    slot="head"
                >
                    <th class="th py-22 md:w-2/6 lg:w-1/4 xl:w-1/3 text-left pl-20">{{ $t('formsTabelColumn_1') }}</th>
                    <th class="th py-22 md:w-1/6 lg:w-1/4 xl:w-1/6 text-left pl-20">{{ $t('formsTabelColumn_2') }}</th>
                    <th class="th py-22 md:w-1/6 lg:w-1/4 xl:w-1/6 text-left pl-20">{{ $t('formsTabelColumn_3') }}</th>
                    <th class="th py-22 md:w-2/6 lg:w-1/4 xl:w-2/5 text-left pl-20"></th>
                </thead>
                <!-- Form table rows -->
                <tbody
                    id="table_body"
                    class="tbody"
                    slot="body"
                    slot-scope="{displayData}"
                >
                    <tr
                        class="border-b pr-20 md:pr-0"
                        v-for="form in displayData"
                        :key="form.id"
                    >
                        <!-- Form name -->
                        <td class="p text-gray-800">
                            <div class="flex items-center">
                                <span class="th text-left lg:hidden mr-7">{{ $t('formsTabelColumn_1') }}:</span>
                                <router-link
                                    :to='{name:"FormPreview", params: { slug: `${form.id}` } }'
                                    target="_blank"
                                    class="flex items-center"
                                >
                                    <img
                                        class="mr-10 w-16 h-16"
                                        :src="require('@/assets/images/icons/layout.svg')"
                                        alt=""
                                    >
                                    <p class="text-purple-600 font-titillium-normal underline">{{ form.name }}</p>
                                </router-link>
                            </div>
                        </td>
                        <!-- Form cases number -->
                        <td class="p text-base font-bold font-titillium-bold text-purple-600">
                            <div class="flex items-center">
                                <span class="th text-left lg:hidden mr-7">{{ $t('formsTabelColumn_2') }}:</span>
                                {{ form.cases_number }}
                            </div>
                        </td>
                        <!-- Form status -->
                        <td class="text-base font-bold font-titillium-normal text-purple-600">
                            <div class="flex items-center">
                                <span class="th text-left lg:hidden mr-7">{{ $t('formsTabelColumn_3') }}:</span>
                                <div
                                    class="text-center rounded-full w-85 py-4 small"
                                    :class="BindCaseStatus(form.status)"
                                >
                                    {{ form.status === 'draft' ? $t('tableStatusDraft') : $t('tableStatusPublished') }}
                                </div>
                            </div>
                        </td>
                        <!-- Form options -->
                        <td>
                            <div class="flex items-center justify-start xl:pr-20">
                                <!-- Preview button -->
                                <router-link
                                    :to='{name:"FormPreview", params: { slug: `${form.id}` } }'
                                    target="_blank"
                                    class="px-0 lg:px-4 xl:px-20 flex items-center"
                                >
                                    <p class="font-bold font-titillium-bold small hidden xl:block text-gray-500">{{ $t('formsTabelRowSeeForm') }}</p>
                                    <img
                                        class="ml-0 lg:ml-10 w-22 h-22"
                                        :src="require('@/assets/images/icons/eye.svg')"
                                        alt=""
                                    >
                                </router-link>
                                <!-- Edit button -->
                                <router-link
                                    :to='{name:"FormCreate", params: { slug: `${form.id}` } }'
                                    class="px-4 xl:px-20 flex items-center"
                                    v-if="form.status === 'draft'"
                                >
                                    <p class="font-bold font-titillium-bold small hidden xl:block text-gray-500">{{ $t('formsTabelRowEditForm') }}</p>
                                    <img
                                        class="ml-10 w-22 h-22"
                                        :src="require('@/assets/images/icons/edit.svg')"
                                        alt=""
                                    >
                                </router-link>
                                <!-- Delete button -->
                                <div class="px-4 xl:px-20 pr-20 xl:pr-0">
                                    <button
                                        @click="form.cases_number >= 1 ? openDeleteModal(form) : deleteFormRow(form)"
                                        role="submit"
                                        class="flex outline-none focus:outline-none"
                                    >
                                        <p class="font-bold font-titillium-bold small hidden xl:block text-red-500">{{ $t('formsTabelRowDeleteForm') }}</p>
                                        <img
                                            class="ml-10 w-22 h-22 xl:w-10 xl:h-10"
                                            :src="require('@/assets/images/icons/rounded_red_close.svg')"
                                            alt=""
                                        >
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <v-table
                v-else
                class="table border-none"
                :data="allForms"
            >
                <tbody
                    class="tbody grid grid-cols-12"
                    slot="body"
                >
                    <tr
                        class="col-span-12"
                    >
                        <td class="font-titillium-normal text-gray-800">
                            {{$t('noRecordsText')}}
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <!-- Pagination -->
            <div
                v-if="allForms.length"
                class="flex items-center justify-between mt-30"
            >
                <div class="pl-10 p text-gray-800">{{page}}-{{pageSizeIncrement}} {{ $t('fromText') }} {{allForms.length}} {{allForms.length > 1 ? $t('fromFromsText') : $t('fromFromsTextSingle') }}</div>
                <div>
                    <smart-pagination
                        :currentPage.sync="currentPage"
                        :totalPages="totalPages"
                    />
                </div>
            </div>
        </div>
        <!-- Modal for initiating form create -->
        <Modal
            width='w-518 md:w-768'
            v-if="showAddModal"
            @close="showAddModal = false"
            @closeOnEscPressed="showAddModal = false"
        >
            <!-- Title -->
            <div slot="title">
                <h2 class="font-4xl opacity-85 font-titillium-bold text-gray-800">{{$t('formsModalAddFormTitle')}}</h2>
            </div>
            <!-- Description -->
            <p
                class="my-30 p text-gray-800 font-titillium-normal tracking-tight"
                slot="subtitle"
            >
                {{$t('formsModalAddFormSubtitle')}}
            </p>
            <!-- Modal body -->
            <div slot="body">
                <div class="flex items-center justify-between mb-15">
                    <!-- Input for form name -->
                    <Input
                        class="mr-30 mb-22"
                        v-model="form_name"
                        :label="$t('formsFormNameFieldLabel')"
                        :placeholder="$t('formsFormNameFieldPlaceholder')"
                        type="text"
                        name="form_name"
                    />
                    <!-- Initiate form create button -->
                    <router-link
                        :to='{ name: "FormCreate", params: { formName: this.form_name } }'
                        class="button flex items-center justify-center bg-purple-500 text-white hover:bg-purple-600 mt-4"
                    >
                        {{$t('formsCreateFormBtnText')}}
                    </router-link>
                </div>
            </div>
        </Modal>
        <!-- Delete member modal -->
        <Modal
            width='w-420'
            v-if="showDeleteModal"
            @close="showDeleteModal = false"
            @closeOnEscPressed="showDeleteModal = false"
        >
            <div slot="title">
                <h2 class="font-4xl text-gray-800 font-titillium-bold opacity-85">{{ $t('deleteFormTitle') }}</h2>
            </div>
            <div
                class="my-30"
                slot="subtitle"
            >
                <p class="p text-gray-800">
                    {{ $t('deleteFormSubtitle') }} {{form.name}}?
                </p>
                <p class="p text-gray-800 font-titillium-bold mt-10 opacity-85">{{form.cases_number > 1 ? $t('deleteFormInfoText2') : $t('deleteFormInfoText1')}}</p>
            </div>
            <div slot="body">
                <div class="grid grid-cols-2 gap-30">
                    <Button
                        @btnClick="showDeleteModal = false"
                        class="button bg-gray-500 hover:bg-gray-600 text-white w-full px-30 mb-30"
                        role="button"
                        :text="$t('deleteServiceNoButtonText')"
                    />
                    <Button
                        @btnClick="deleteFormRow(form)"
                        class="button bg-red-500 hover:bg-red-600 text-white w-full px-30 mb-30"
                        role="button"
                        :text="$t('deleteServiceYesButtonText')"
                    />
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import Vue from 'vue'
import Button from '@/components/buttons/Button'
import Modal from '@/components/modal/Modal'
import Input from '@/components/form/Input'
import { mapActions, mapGetters } from 'vuex'
import SuccessBox from '@/components/success-message/SuccessBox'

export default {
    name: 'Forms',
    components: {
        Button,
        Modal,
        Input
    },
    props: ['showConfirmationBox'],
    data () {
        return {
            form_name: '',
            currentPage: 1,
            totalPages: 0,
            pageSizeIncrement: null,
            currentPageSize: null,
            page: null,
            showAddModal: false,
            pageSize: 20,
            showDeleteModal: false,
            form: ''
        }
    },
    computed: {
        /** Vuex get methods. */
        ...mapGetters(['token', 'allForms', 'userType', 'manager', 'error', 'deleteStatus'])
    },

    methods: {
        /** Vuex actions for form builder. */
        ...mapActions(['getForms', 'deleteForm']),

        /** Delete table row. */
        deleteFormRow (item) {
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.deleteForm({ token: this.token, id: item.id }).then(response => {
                if (this.deleteStatus === true) {
                    this.allForms.splice(this.allForms.indexOf(item), 1)

                    /** Dynamically mount success box to page */
                    const instance = new SuccessBoxComponent({
                        propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('deleteSuccessMessage'), success: true }
                    })
                    instance.$mount()
                    this.$refs.successBoxContainer.appendChild(instance.$el)

                    this.showDeleteModal = false
                } else {
                    if (this.error !== '') {
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    }
                }
            })
        },

        /** Add css class based on database value for form status. */
        BindCaseStatus (status) {
            if (status === 'draft') {
                return 'bg-gray-500 text-white'
            } else {
                return 'bg-purple-500 text-white'
            }
        },

        /** Open confirmation modal for deleting file. */
        openDeleteModal (item) {
            this.form = item
            this.showDeleteModal = true
        },

        /** Display info table in table footer method */
        getTableRecordsInfo () {
            this.currentPageSize = document.getElementById('table_body')?.childElementCount
            this.page = (this.pageSize * (this.currentPage - 1)) + 1

            if (this.allForms.length < (this.pageSize * this.currentPage)) {
                this.pageSizeIncrement = (this.pageSize * (this.currentPage - 1)) + this.currentPageSize
            } else {
                this.pageSizeIncrement = (this.pageSize * this.currentPage)
            }
        }
    },
    updated () {
        this.getTableRecordsInfo()
    },
    mounted () {
        /** Extract all forms form api. */
        this.getForms({ token: this.token }).then(() => {
            this.getTableRecordsInfo()
        })

        if (this.showConfirmationBox) {
            const SuccessBoxComponent = Vue.extend(SuccessBox)
            /** Dynamically mount success box to page */
            const instance = new SuccessBoxComponent({
                propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('createSuccessMessage'), success: true }
            })
            instance.$mount()
            this.$refs.successBoxContainer.appendChild(instance.$el)
        }

        /** Redirect to dashboard if user is not ngo manager. */
        if (this.userType !== this.manager) {
            this.$router.push({ name: 'Dashboard' })
        }
    }

}
</script>
