<template>
    <div
        class="bg-white rounded-lg shadow-custom p-12 flex mt-10"
        :class="{view: dissapear}"
    >
        <div class="flex items-start mr-12">
            <img
                :src="success ? require('@/assets/images/icons/success.svg') : require('@/assets/images/icons/close_red.svg')"
                alt="status-icon"
            />
        </div>
        <div>
            <p class="small-bold mr-12">{{statusTitle}}</p>
            <p class="small-bold">{{statusMessage}}</p>
        </div>
        <div class="flex items-start">
            <img
                class="cursor-pointer"
                @click="dissapear = true"
                :src="require('@/assets/images/icons/close_no_border.svg')"
                alt="close-icon"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SuccessBox',
    props: ['statusTitle', 'statusMessage', 'success'],
    data () {
        return {
            dissapear: false
        }
    },
    mounted () {
        let timesRun = 0
        const timer = setInterval(() => {
            timesRun++
            if (timesRun === 6) {
                clearInterval(timer)
                this.dissapear = true
            }
        }, 1000)
    }
}
</script>

<style scoped>
.view {
    display: none;
}
</style>
