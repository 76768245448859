<template>
    <div class="w-full">
        <label class="flex">
            {{label}}
            <span
                class="text-red-500"
                v-if="isRequired === true"
            >*</span>
        </label>
        <div
            v-if="type === 'password'"
            class="relative flex items-center"
        >
            <input
                class="input pl-16"
                :class="[error ? 'border-red-500' : 'border-gray-200']"
                :type="[showPassword ? 'text' : 'password']"
                :placeholder="placeholder"
                :name="name"
                @input='$emit("input", $event.target.value)'
                :default-value.prop="value"
                ref="input"
            >
            <img
                @click="showPassword = !showPassword"
                class="absolute right-1 h-36 w-48 p-7 cursor-pointer"
                :src="require('@/assets/images/passwordVisibility.svg')"
                alt="passwordVisibility"
                width="30"
            >
        </div>
        <div
            v-else
            class="relative"
        >
            <input
                class="input pr-16"
                :class="[error ? 'border-red-500' : 'border-gray-200', type === 'phone' ? 'pl-48' : 'pl-16']"
                :type="type"
                :placeholder="placeholder"
                :name="name"
                @input='$emit("input", $event.target.value)'
                v-bind="$attrs"
                :default-value.prop="value"
                ref="input"
            >
            <div
                v-if="type === 'phone'"
                class="absolute top-0 w-48 h-48 flex items-center justify-center"
            >
                <p class="text-purple-900 text-base font-normal font-titillium-normal">{{prefix}}</p>
            </div>
        </div>
        <p
            v-if="error"
            class="text-red-500 small mt-4 mb-15 inputErrors"
        >
            {{error[0]}}
        </p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    inheritAttrs: false,
    name: 'Input',
    props: ['label', 'placeholder', 'type', 'name', 'isRequired', 'value', 'error', 'inputLarge'],
    data () {
        return {
            showPassword: false
        }
    },

    computed: {
        /** Get vuex state */
        ...mapGetters(['prefix'])
    },

    methods: {
        getValue () {
            return this.$refs.input.value
        }
    }
}
</script>
