<template>
    <transition name="modal">
        <div
            class="modal-mask"
            @keydown.esc="$emit('closeOnEscPressed')"
            tabindex="0"
            ref="modal"
        >
            <div class="modal-wrapper">
                <div
                    class="modal-container focus:outline-none"
                    :class="width"
                >

                    <div class="modal-header">
                        <div class="flex items-start justify-between">
                            <slot name="title">
                                default header
                            </slot>
                            <button
                                @click="$emit('close')"
                                class="flex items-center focus:outline-none"
                            >
                                <span class="small mr-15 text-gray-500">esc</span>
                                <img
                                    class="w-12 h-12"
                                    :src="require('@/assets/images/icons/close_no_border.svg')"
                                    alt=""
                                >
                            </button>

                        </div>
                        <slot name="subtitle"></slot>
                    </div>
                    <div class="modal-body">
                        <slot name="body">
                            default body
                        </slot>
                    </div>

                    <!-- <div class="modal-footer">
                        <slot name="footer">
                            default footer
                        </slot>
                    </div> -->
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: ['width'],
    mounted () {
        this.$refs.modal.focus()
    }
}
</script>
