<template>
    <button
        class="focus:outline-none flex items-center justify-evenly"
        :class="[profileBtn ? 'profile-button' : 'general-button']"
        :disabled="disabled"
        :role="role"
        :type="role"
        @click="$emit('btnClick')"
    ><img :class="[spinning ? 'visible' : 'hidden']" :src="require('@/assets/images/spiner.gif')" alt="spinner" width="30">{{text}}</button>
</template>

<script>
export default {
    name: 'Button',
    props: ['role', 'text', 'disabled', 'profileBtn', 'spinning']
}
</script>
